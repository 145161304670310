import { useState, useRef, useEffect } from 'react';
import { useInView, useAnimate } from 'framer-motion';
import useDetectScroll, { Axis } from '@smakss/react-scroll-direction';
import { Grid } from 'antd';

import { useLogoState } from 'context/LogoContext';

import {
  Wrapper,
  Container,
  VideoWrapper,
  VideoPlayer,
  Thumbnail,
  PlayButton,
} from './styles';

import videoThumbnail from 'assets/images/home/video/thumbnail-v1.jpg';
import posthog from 'posthog-js';

export interface HomePageVideoProps {
  copy: any;
}

export default function HomePageVideo({ copy }: HomePageVideoProps) {
  const screens = Grid.useBreakpoint();
  const [playing, setPlaying] = useState(false);
  const [videoVisible, setVideoVisible] = useState(false);
  const [playClicked, setPlayClicked] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [scope, animate] = useAnimate();

  const hideTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const containerRef = useRef(null);
  const isHoveringRef = useRef(false);

  const { setIsDarkBackground } = useLogoState();
  const isInView = useInView(containerRef, { amount: 0.5 });

  const { scrollDir } = useDetectScroll({
    axis: Axis.Y,
    target:
      typeof window !== 'undefined'
        ? (document.getElementById('Home-mainContainer') as HTMLDivElement)
        : null,
  });

  useEffect(() => {
    if (isInView) setIsDarkBackground(false);
    if (videoVisible) setIsDarkBackground(true);
  }, [isInView, videoVisible, setIsDarkBackground]);

  useEffect(() => {
    if (playing && !isInView) setPlaying(false);
  }, [playing, isInView]);

  useEffect(() => {
    const enterAnimation = async () => {
      await animate(
        scope.current,
        { scale: 1, x: '-50%', y: '-50%' },
        { duration: 0 }
      );
      await animate(
        scope.current,
        { scale: 1.85, x: '-50%', y: '-50%' },
        { duration: 0.5, ease: [0.83, 0, 0.17, 1] }
      );
      setVideoVisible(true);
    };
    // const exitAnimation = async () => {
    //   setVideoVisible(false);
    //   await animate(
    //     scope.current,
    //     { scale: 1, x: '-50%', y: '-50%' },
    //     { duration: 0.5, ease: [0.83, 0, 0.17, 1], delay: 0.5 }
    //   );
    //   // Remove applied styles after animation completes
    //   scope.current.style.transform = '';
    // };
    if (playClicked) enterAnimation();
    // else exitAnimation();
  }, [playClicked, animate, scope]);

  const handleMouseMove = () => {
    setShowButton(true);

    if (hideTimeoutRef.current) {
      clearTimeout(hideTimeoutRef.current);
    }

    if (playing) {
      hideTimeoutRef.current = setTimeout(() => {
        if (!isHoveringRef.current) {
          setShowButton(false);
        }
      }, 1000);
    }
  };

  const handleMouseEnter = () => {
    isHoveringRef.current = true;
    setShowButton(true);
  };

  const handleMouseLeave = () => {
    isHoveringRef.current = false;

    if (playing) {
      hideTimeoutRef.current = setTimeout(() => {
        setShowButton(false);
      }, 1000);
    }
  };

  const handlePlayClick = () => {
    setPlayClicked(true);
    setPlaying(!playing);
    posthog.capture('homepage_video_action', {
      action: playing ? 'pause' : 'play',
      page: 'homepage',
    });
  };

  const isMobile = !screens.md;

  return (
    <Wrapper
      $isMobile={isMobile}
      $playing={playing}
      ref={containerRef}
      $videoVisible={videoVisible}
    >
      <Container>
        <Thumbnail
          ref={scope}
          src={videoThumbnail}
          alt="Thumbnail"
          $playing={playing}
          $videoVisible={videoVisible}
          $isInView={isInView}
          $scrollDir={scrollDir}
          $isMobile={isMobile}
        />
        <PlayButton
          src={`/images/${playing ? 'pause' : 'play'}-icon.svg`}
          onClick={handlePlayClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          $visible={showButton}
          $isInView={isInView}
          $isMobile={isMobile}
        />
        <VideoWrapper
          onMouseMove={handleMouseMove}
          $videoVisible={videoVisible}
        >
          <VideoPlayer
            url="https://vimeo.com/1010932211"
            playing={playing}
            width="100%"
            height="100%"
            config={{
              vimeo: {
                pip: false,
                airplay: false,
                byline: false,
                chromecast: false,
                controls: false,
                keyboard: false,
                playsinline: true, // Enable inline playback on mobile devices
              },
            }}
            playsinline={true} // Enable inline playback on mobile devices
            onPause={() => {
              setPlaying(false);
              clearTimeout(hideTimeoutRef.current);
            }}
          />
        </VideoWrapper>
      </Container>
    </Wrapper>
  );
}
