import styled, { css } from 'styled-components';
import Image from 'next/image';
import { Typography, Row, Collapse } from 'antd';
import { timingFunctions } from 'polished';

import theme from 'styles/theme';

interface CommonProps {
  $isMobile?: boolean;
  $isInView?: boolean;
}

export const FAQBackground = styled.div`
  &&& {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
  }
`;

export const FAQGradient = styled(Image)`
  &&& {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const FAQContainer = styled(Row)<CommonProps>`
  &&& {
    position: relative;
    padding: ${(props) =>
      props.$isMobile ? '120px 30px 200px' : '100px 60px'};
    max-width: ${theme.size.maxWidth};
    margin: 0 auto;
  }
`;

export const FAQCollapse = styled(Collapse)`
  &&& {
    border: none;
    border-radius: 0;
  }
`;

function createCSS() {
  let styles = '';

  for (let i = 0; i < 5; i += 1) {
    styles += `
       &.FAQCollapsePanel_${i} {
         transition-delay: ${0.3 + i * 0.2}s;
       }
     `;
  }

  return css`
    ${styles}
  `;
}

export const FAQCollapsePanel = styled(Collapse.Panel)<CommonProps>`
  &&& {
    padding: ${(props) => (props.$isMobile ? '10px 0' : '20px 0')};
    border-radius: 0;
    border-radius: 0;
    opacity: ${(props) => (props.$isInView ? 1 : 0)};
    transform: ${(props) =>
      props.$isInView ? 'translateY(0px)' : 'translateY(50px)'};

    transition-property: opacity, transform;
    transition-duration: 1.5s;
    transition-timing-function: ${timingFunctions('easeOutQuart')};

    ${createCSS()};

    &:first-child {
      padding-top: 0;
      .ant-collapse-header {
        padding-top: 5px;
      }
    }

    .ant-collapse-header-text {
      width: 100%;
    }

    .ant-collapse-header {
      align-items: center;
      position: relative;
      margin-bottom: 5px;
      padding-bottom: 20px;
      padding-left: 0;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 1px;
        width: calc(100% - 20px);
        height: 1px;
        background-color: rgba(0, 0, 0, 0.1);
      }
    }

    .ant-collapse-content-box {
      padding-left: 0;
    }
  }
`;

export const FAQCollapsePanelTitle = styled(Typography.Title)<CommonProps>`
  &&& {
    margin-bottom: 0;
    max-width: ${(props) => (props.$isMobile ? '100%' : '100%')};
  }
`;

export const FAQCollapsePanelAnswer = styled(Typography.Paragraph)`
  &&& {
    margin-bottom: 0;
    width: 100%;
    color: ${theme.colors.textSecondary};
  }
`;
