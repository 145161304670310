import styled from 'styled-components';
import { Tag } from 'antd';

import theme from 'styles/theme';

export const PageContainer = styled.main`
  width: 100%;
  height: auto;
  background-color: ${theme.colors.white};

  @media (max-width: 768px) {
    padding: 0;
    overflow: hidden;
  }
`;

export const ContentWrapper = styled.div`
  max-width: ${theme.size.maxWidth};
  padding: 60px;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 0px;
  }
`;

export const StyledTag = styled(Tag)`
  &&& {
    padding: 2px 8px;
    border-radius: 25px;
    margin: 0;
    font-size: 13px;
    cursor: inherit;
    font-weight: 400;
    pointer-events: none;
  }
`;
