import { useEffect, useRef } from 'react';
import { Grid, Col } from 'antd';
import { useQuery } from '@apollo/client';
import { useInView } from 'framer-motion';

import { ResourceProps } from 'types';

import { GET_HOMEPAGE_RESOURCES_QUERY } from 'queries';

import AnimatedTitle from 'components/AnimatedTitle';
import ResourceCard from 'components/ResourceCard';
import { ResourceCardSkeleton } from 'components/ResourceCard/styles';

import { useLogoState } from 'context/LogoContext';

import { flattenEntities } from 'utils/graphql';

import {
  EducationBackground,
  EducationContainer,
  EducationCardWrapper,
} from './styles';

export interface HomePageEducationProps {
  copy: any;
}

export default function HomePageEducation({ copy }: HomePageEducationProps) {
  const screens = Grid.useBreakpoint();

  const { setIsDarkBackground } = useLogoState();
  const containerRef = useRef(null);
  const isInView = useInView(containerRef, { amount: 0.5 });

  useEffect(() => {
    if (isInView) setIsDarkBackground(false);
  }, [isInView, setIsDarkBackground]);

  const { data: resourceData } = useQuery(GET_HOMEPAGE_RESOURCES_QUERY);
  const resources: ResourceProps[] = flattenEntities(
    resourceData?.resources
  )?.data;

  const isMobile = !screens.md;

  return (
    <EducationBackground>
      <EducationContainer ref={containerRef} $isMobile={isMobile}>
        <AnimatedTitle
          text={copy.title}
          level={screens.md ? 1 : 3}
          isInView={isInView}
          once={false}
          style={{
            marginBottom: isMobile ? 30 : 50,
            textAlign: 'center',
          }}
        />
        <EducationCardWrapper
          gutter={10}
          align="middle"
          justify="start"
          wrap={false}
          $isMobile={isMobile}
        >
          {resources.map((resource, index) => (
            <Col key={resource.id}>
              <ResourceCard
                resource={resource}
                index={index}
                isInView={isInView}
              />
            </Col>
          ))}
          {/* {resources.length < 5 && (
            <Col>
              <ResourceCardSkeleton
                $isInView={isInView}
                $index={resources.length}
                $isMobile={isMobile}
              />
            </Col>
          )} */}
        </EducationCardWrapper>
      </EducationContainer>
    </EducationBackground>
  );
}
