import styled, { keyframes, css } from 'styled-components';
import { CaretDownOutlined } from '@ant-design/icons';
import Image from 'next/image';
import { Typography, Row, Space } from 'antd';
import { timingFunctions } from 'polished';

import Strike from './Strike';

import theme from 'styles/theme';

const scroll = keyframes`
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0px);
  }
`;

export const HeroBackground = styled.div`
  &&& {
    position: relative;
    width: 100%;
    height: 100vh;
  }
`;

export const Hero = styled(Row)<CommonProps>`
  &&& {
    margin: 0 auto;
    position: relative;
    height: 100%;
  }
`;

export const HeroImage = styled(Image)<CommonProps>`
  position: absolute;
  width: auto;
  height: ${(props) => (props.$isMobile ? '110%' : '99%')};
  top: ${(props) => (props.$isMobile ? '48%' : '50%')};
  left: ${(props) => (props.$isMobile ? '0' : '40%')};
  opacity: ${(props) => (props.$isInView ? 1 : 0)};
  transform: translateY(${(props) => (props.$isInView ? '-50%' : '-80%')})
    rotateX(${(props) => (props.$isInView ? '0deg' : '5deg')})
    rotateY(${(props) => (props.$isInView ? '0deg' : '-5deg')})
    rotateZ(${(props) => (props.$isInView ? '0deg' : '5deg')});

  transition:
    opacity 1s ease-out,
    transform 1.5s ${(props) => (!props.$isInView ? '1s' : '0s')}
      ${timingFunctions('easeOutQuint')};
`;

interface CommonProps {
  $isMobile?: boolean;
  $isInView?: boolean;
}

export const HeroTitle = styled(Typography.Title)<CommonProps>`
  &&& {
    position: absolute;
    top: ${(props) => (props.$isMobile ? '18%' : '20%')};
    left: ${(props) => (props.$isMobile ? '30px' : '60px')};
    color: ${theme.colors.white};
    margin-bottom: ${(props) => (props.$isMobile ? '10px' : '20px')};
    font-size: ${(props) => (props.$isMobile ? '90px' : '8vw')};
    line-height: ${(props) => (props.$isMobile ? '84px' : '8vw')};

    span {
      display: block;
      opacity: ${(props) => (props.$isInView ? 1 : 0)};
      transform: ${(props) =>
        props.$isInView ? 'translateY(0px)' : 'translateY(20px)'};
      transition:
        opacity 1s 0.5s ease-out,
        transform 0.5s 0.5s ${timingFunctions('easeOutQuad')};
    }

    span:first-child {
      color: #679f98;
    }

    span:last-of-type {
      transition:
        opacity 1s 0.6s ease-out,
        transform 0.5s 0.6s ${timingFunctions('easeOutQuad')};
    }

    /* If the above doesn't work, there might be inline styles or JavaScript manipulating the element.
    In that case, we'd need to investigate the rendered DOM and any related JavaScript. */
  }
`;

export const HeroStrike = styled.svg<CommonProps>`
  &&& {
    position: absolute;
    top: ${(props) => (props.$isMobile ? '14%' : '14%')};
    left: ${(props) => (props.$isMobile ? '-10px' : '-10px')};
    width: ${(props) => (props.$isMobile ? '300px' : '26vw')};
    height: auto;
    z-index: 1;
  }
`;

export const HeroFooter = styled(Space)<CommonProps>`
  position: absolute;
  bottom: ${(props) => (props.$isMobile ? '120px' : '60px')};
  left: ${(props) => (props.$isMobile ? '30px' : '60px')};
`;

export const HeroSubtitle = styled(Typography.Paragraph)<CommonProps>`
  &&& {
    max-width: 450px;
    padding: ${(props) => (props.$isMobile ? '0 20px 0 0' : '0')};
    color: ${theme.colors.white};
    margin-bottom: 0;
    font-size: ${(props) => (props.$isMobile ? '22px' : '22px')};
    text-shadow: ${(props) =>
      props.$isMobile ? '0px 0px 10px rgba(0, 0, 0, 0.25)' : 'none'};
    opacity: ${(props) => (props.$isInView ? 1 : 0)};
    transform: ${(props) =>
      props.$isInView ? 'translateY(0px)' : 'translateY(10px)'};

    transition:
      opacity 1s 0.8s ease-out,
      transform 0.5s 0.8s ${timingFunctions('easeOutQuad')};
  }
`;

export const HeroCta = styled(Typography.Link)<CommonProps>`
  &&& {
    /* display: block; */
    color: ${theme.colors.white};
    text-decoration: underline;
    margin-bottom: ${(props) => (props.$isMobile ? '5px' : '0')};
    opacity: ${(props) => (props.$isInView ? 1 : 0)};
    text-shadow: ${(props) =>
      props.$isMobile ? '0px 0px 10px rgba(0, 0, 0, 0.25)' : 'none'};
    transform: ${(props) =>
      props.$isInView ? 'translateY(0px)' : 'translateY(10px)'};

    transition:
      color 0.15s ease-out,
      opacity 1s 0.9s ease-out,
      transform 0.5s 0.9s ${timingFunctions('easeOutQuad')};

    &:hover {
      color: rgba(255, 255, 255, 0.9);
    }
  }
`;

export const HeroRegs = styled(Typography.Text)<CommonProps>`
  &&& {
    width: 80%;
    display: block;
    color: ${theme.colors.coolGrey};
    margin-bottom: ${(props) => (props.$isMobile ? '5px' : '0')};
    opacity: ${(props) => (props.$isInView ? 1 : 0)};
    text-shadow: ${(props) =>
      props.$isMobile ? '0px 0px 10px rgba(0, 0, 0, 0.25)' : 'none'};
    transform: ${(props) =>
      props.$isInView ? 'translateY(0px)' : 'translateY(10px)'};

    transition:
      opacity 1s 1s ease-out,
      transform 0.5s 0.9s ${timingFunctions('easeOutQuad')};
  }
`;

interface ScrollIndicatorProps extends CommonProps {
  $scrollHappened?: boolean;
}

export const ScrollIndicator = styled(Typography.Text)<ScrollIndicatorProps>`
  &&& {
    display: block;
    color: ${theme.colors.coolGrey};
    opacity: ${(props) => (props.$isInView ? 1 : 0)};
    transform: ${(props) =>
      props.$isInView ? 'translateY(0px)' : 'translateY(10px)'};

    transition:
      opacity 1s 1.1s ease-out,
      transform 0.5s 1s ${timingFunctions('easeOutQuad')};

    > span {
      color: ${theme.colors.coolGrey};
    }
  }
`;

export const ScrollIndicatorIcon = styled(CaretDownOutlined)`
  &&& {
    display: inline-block;
    margin-left: 5px;
    animation: 1.5s ${timingFunctions('easeOutQuad')} infinite ${scroll};
  }
`;
