import Link from 'next/link';
import { Grid } from 'antd';
import posthog from 'posthog-js';
import { ResourceProps } from 'types';

import {
  ResourceCard,
  ResourceCardImage,
  ResourceCardContent,
  ResourceCardTag,
  ResourceCardTitle,
  ResourceCardDescription,
  ResourceCardCta,
  ResourceCardContentGradient,
} from './styles';

export interface ResourceCardProps {
  resource: ResourceProps;
  index?: number;
  isInView?: boolean;
}

const ResourceCardComponent = ({
  resource,
  index = 0,
  isInView = true,
}: ResourceCardProps) => {
  const screens = Grid.useBreakpoint();

  const isMobile = !screens.md;

  return (
    <ResourceCard
      bordered={false}
      $isMobile={isMobile}
      $isInView={isInView}
      $index={index}
    >
      <ResourceCardImage src={resource.thumbnail.url} alt="" />
      <ResourceCardContent $isMobile={isMobile}>
        <ResourceCardTag>{resource.tag}</ResourceCardTag>
        <ResourceCardTitle level={isMobile ? 5 : 4} $isMobile={isMobile}>
          {resource.title}
        </ResourceCardTitle>
        <ResourceCardDescription ellipsis={{ rows: 3 }}>
          {resource.description}
        </ResourceCardDescription>
        <Link href={`/insights/${resource.slug}`}>
          <ResourceCardCta
            type="primary"
            onClick={() => {
              posthog.capture('resource_card_read_more_click', {
                resource: resource.title,
              });
            }}
          >
            Read more
          </ResourceCardCta>
        </Link>
      </ResourceCardContent>
      <ResourceCardContentGradient />
    </ResourceCard>
  );
};

export default ResourceCardComponent;
