import styled, { css } from 'styled-components';
import { Card, Typography, Button, Tag, Space, Row } from 'antd';
import { timingFunctions, rgba } from 'polished';

import theme from 'styles/theme';

interface CommonProps {
  $isMobile?: boolean;
  $isInView?: boolean;
  $index?: number;
}

const commonCardStyles = css<CommonProps>`
  transform: ${(props) =>
    props.$isInView ? 'translateY(0px)' : 'translateY(120px)'};
  opacity: ${(props) => (props.$isInView ? 1 : 0)};

  transition:
    transform 1s ${timingFunctions('easeOutQuart')}
      ${(props) => 0.2 + props.$index * 0.1}s,
    opacity 1s ${timingFunctions('easeOutQuart')}
      ${(props) => 0.2 + props.$index * 0.1}s;
`;

export const ResourceCard = styled(Card)<CommonProps>`
  &&& {
    overflow: hidden;
    position: relative;
    height: ${(props) => (props.$isMobile ? '450px' : '500px')};
    width: ${(props) => (props.$isMobile ? '300px' : '350px')};

    ${commonCardStyles}

    .ant-card-body {
      padding: 0;
    }
  }
`;

export const ResourceCardImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
`;

export const ResourceCardContent = styled(Row)<CommonProps>`
  &&& {
    padding: ${(props) => (props.$isMobile ? '0 20px 20px' : '0 30px 30px')};
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 2;
    flex-direction: column;
  }
`;

export const ResourceCardTag = styled.div`
  &&& {
    display: block;
    padding: 4px 10px;
    border: 1px solid ${theme.colors.lightGrey}50;
    border-radius: 25px;
    color: ${theme.colors.lightGrey};
    font-size: 14px;
    width: fit-content;
    margin-bottom: 15px;
    cursor: default;
  }
`;

export const ResourceCardTitle = styled(Typography.Title)<CommonProps>`
  &&& {
    color: ${theme.colors.white};
    max-width: ${(props) => (props.$isMobile ? '100%' : '300px')};
    margin-bottom: 6px;
  }
`;

export const ResourceCardDescription = styled(Typography.Paragraph)`
  &&& {
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    opacity: 0.6;
    color: ${theme.colors.white};
    margin-bottom: 0;
  }
`;

export const ResourceCardCta = styled(Button)`
  &&& {
    margin-top: 20px;

    /* &:hover {
      &&& {
        background-color: ${theme.colors.darkTeal};
        color: ${theme.colors.white};
      }
    } */
  }
`;

export const ResourceCardContentGradient = styled.div`
  &&& {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    z-index: 1;
    background: rgb(188, 180, 230);
    background: linear-gradient(
      45deg,
      ${rgba(theme.colors.lavenderGrey, 1)} 0%,
      ${rgba(theme.colors.lavenderGrey, 0.8)} 20%,
      ${rgba(theme.colors.lavenderGrey, 0)} 50%
    );
  }
`;

interface ResourceCardSkeletonProps extends CommonProps {
  $index?: number;
}

export const ResourceCardSkeleton = styled.div<ResourceCardSkeletonProps>`
  &&& {
    height: ${(props) => (props.$isMobile ? '450px' : '500px')};
    width: 150px;
    border-radius: 15px;
    background: linear-gradient(
      to right,
      ${theme.colors.lightGrey} 8%,
      ${theme.colors.white} 100%
    );

    ${commonCardStyles}
  }
`;
