import { useEffect, useRef, useState } from 'react';
import { Col, Grid } from 'antd';
import { useInView } from 'framer-motion';
import { AnimatePresence } from 'framer-motion';
import posthog from 'posthog-js';
import AnimatedTitle from 'components/AnimatedTitle';

import { useLogoState } from 'context/LogoContext';

import theme from 'styles/theme';
import {
  RedefinedBackground,
  RedefinedContainer,
  RedefinedBubbleContainer,
  RedefinedBubbleOverflowWrapper,
  RedefinedAccessContainer,
  RedefinedBubbleTitle,
  RedefinedBubbleDescription,
  RedefinedTrustContainer,
  RedefinedSimplicityContainer,
} from './styles';

export interface HomePageRedefinedProps {
  copy: any;
}

export default function HomePageRedefined({ copy }: HomePageRedefinedProps) {
  const screens = Grid.useBreakpoint();
  const isMobile = !screens.lg;
  const containerRef = useRef(null);
  const isInView = useInView(containerRef, { amount: 0.2 });

  const { setIsDarkBackground } = useLogoState();
  const isInViewLogo = useInView(containerRef, { amount: 0.5 });

  const accessRef = useRef<HTMLDivElement>(null);
  const trustRef = useRef<HTMLDivElement>(null);
  const simplicityRef = useRef<HTMLDivElement>(null);

  const activeContainerTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const hoverActiveTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const [activeContainer, setActiveContainer] = useState<string | null>(null);
  const [hoverActive, setHoverActive] = useState<boolean>(false);

  useEffect(() => {
    if (isInViewLogo) setIsDarkBackground(false);
  }, [isInViewLogo, setIsDarkBackground]);

  const handleMouseEnter = (containerName: string) => {
    setActiveContainer(containerName);
    setHoverActive(true);
    posthog.capture('homepage_redefined_hover', {
      container: containerName,
      page: 'homepage',
    });
  };

  const handleMouseLeave = () => {
    if (activeContainerTimeoutRef.current) {
      clearTimeout(activeContainerTimeoutRef.current);
    }
    if (hoverActiveTimeoutRef.current) {
      clearTimeout(hoverActiveTimeoutRef.current);
    }

    setActiveContainer(null);
    hoverActiveTimeoutRef.current = setTimeout(() => {
      setHoverActive(false);
    }, 1000);
  };

  const getActiveLongDescription = (containerName: string) => {
    if (containerName === 'access') return copy.value1.longDescription;
    if (containerName === 'trust') return copy.value2.longDescription;
    if (containerName === 'simplicity') return copy.value3.longDescription;
  };

  return (
    <RedefinedBackground $isMobile={isMobile}>
      <RedefinedContainer
        align="middle"
        justify="center"
        ref={containerRef}
        $isInView={isInView}
        $isMobile={isMobile}
      >
        <Col md={24} lg={12} style={{ order: isMobile ? 1 : 0 }}>
          <RedefinedBubbleContainer $isMobile={isMobile} $isInView={isInView}>
            <RedefinedBubbleOverflowWrapper>
              <RedefinedAccessContainer
                $isMobile={isMobile}
                $isInView={isInView}
                $isActive={activeContainer === 'access'}
                $hoverActive={hoverActive}
                ref={accessRef}
                onMouseEnter={() => handleMouseEnter('access')}
                onMouseLeave={handleMouseLeave}
              >
                <RedefinedBubbleTitle
                  level={screens.md ? 3 : 4}
                  $isMobile={isMobile}
                  $isInView={isInView}
                  $isActive={activeContainer === 'access'}
                >
                  {copy.value1.title}
                </RedefinedBubbleTitle>
                <RedefinedBubbleDescription
                  $isMobile={isMobile}
                  $isInView={isInView}
                  $isActive={activeContainer === 'access'}
                  style={{ color: '#639355' }}
                >
                  {copy.value1.description}
                </RedefinedBubbleDescription>
              </RedefinedAccessContainer>

              <RedefinedTrustContainer
                $isMobile={isMobile}
                $isInView={isInView}
                $isActive={activeContainer === 'trust'}
                $hoverActive={hoverActive}
                ref={trustRef}
                onMouseEnter={() => handleMouseEnter('trust')}
                onMouseLeave={handleMouseLeave}
              >
                <RedefinedBubbleTitle
                  level={screens.md ? 3 : 4}
                  $isMobile={isMobile}
                  $isInView={isInView}
                  $isActive={activeContainer === 'trust'}
                >
                  {copy.value2.title}
                </RedefinedBubbleTitle>
                <RedefinedBubbleDescription
                  $isMobile={isMobile}
                  $isInView={isInView}
                  $isActive={activeContainer === 'trust'}
                  style={{ color: '#55a3a1' }}
                >
                  {copy.value2.description}
                </RedefinedBubbleDescription>
              </RedefinedTrustContainer>
              <RedefinedSimplicityContainer
                $isMobile={isMobile}
                $isInView={isInView}
                ref={simplicityRef}
                onMouseEnter={() => handleMouseEnter('simplicity')}
                onMouseLeave={handleMouseLeave}
              >
                <RedefinedBubbleTitle
                  level={screens.md ? 3 : 4}
                  $isMobile={isMobile}
                  $isInView={isInView}
                >
                  {copy.value3.title}
                </RedefinedBubbleTitle>
                <RedefinedBubbleDescription
                  $isMobile={isMobile}
                  $isInView={isInView}
                  style={{ color: '#6f66a9' }}
                >
                  {copy.value3.description}
                </RedefinedBubbleDescription>
              </RedefinedSimplicityContainer>
            </RedefinedBubbleOverflowWrapper>
          </RedefinedBubbleContainer>
        </Col>
        <Col md={24} lg={12}>
          <AnimatePresence mode="wait">
            {activeContainer ? (
              <AnimatedTitle
                key={`description-${activeContainer}`}
                text={getActiveLongDescription(activeContainer)}
                level={5}
                isInView={isInView}
                once={false}
                delay={0.05}
                showInline={isMobile}
                style={{
                  textAlign: isMobile ? 'center' : 'left',
                  whiteSpace: isMobile ? 'unset' : 'none',
                  marginBottom: isMobile ? '40px' : '0',
                  fontSize: isMobile && '14px',
                  color: theme.colors.bodyText,
                  fontWeight: 400,
                  maxWidth: isMobile ? '100%' : '500px',
                }}
              />
            ) : (
              <AnimatedTitle
                key="title"
                text={copy.title}
                level={screens.lg ? 1 : 3}
                isInView={isInView}
                once={false}
                style={{
                  textAlign: isMobile ? 'center' : 'left',
                  whiteSpace: isMobile ? 'unset' : 'nowrap',
                  marginBottom: isMobile ? '40px' : '0',
                }}
              />
            )}
          </AnimatePresence>
        </Col>
      </RedefinedContainer>
    </RedefinedBackground>
  );
}
