import { useRef, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Grid, Col } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { useInView } from 'framer-motion';
import posthog from 'posthog-js';
import { GET_HOMEPAGE_FAQ_QUERY } from 'queries';

import { FAQProps } from 'types/help';

import AnimatedTitle from 'components/AnimatedTitle';

import { useLogoState } from 'context/LogoContext';

import { flattenEntities } from 'utils/graphql';

import gradiuentImage from 'assets/images/home/FAQ/gradient.png';

import {
  FAQBackground,
  FAQGradient,
  FAQContainer,
  FAQCollapse,
  FAQCollapsePanel,
  FAQCollapsePanelTitle,
  FAQCollapsePanelAnswer,
} from './styles';

export interface HomePageFAQProps {
  copy: any;
}

export default function HomePageFAQ({ copy }: HomePageFAQProps) {
  const screens = Grid.useBreakpoint();
  const containerRef = useRef(null);
  const isInView = useInView(containerRef, { amount: 0.1 });

  const { setIsDarkBackground } = useLogoState();

  useEffect(() => {
    if (isInView) setIsDarkBackground(false);
  }, [isInView, setIsDarkBackground]);

  const { data } = useQuery(GET_HOMEPAGE_FAQ_QUERY);
  const faq: [FAQProps] = flattenEntities(data?.faqs)?.data;

  const isMobile = !screens.md;

  return (
    <FAQBackground>
      <FAQGradient src={gradiuentImage} alt="" />
      <FAQContainer
        ref={containerRef}
        $isInView={isInView}
        $isMobile={isMobile}
      >
        <Col md={24} lg={12}>
          <AnimatedTitle
            text={copy.title}
            level={screens.md ? 1 : 3}
            isInView={isInView}
            once={false}
            style={{ marginBottom: screens.lg ? '0' : '30px' }}
          />
        </Col>
        <Col md={24} lg={12}>
          <FAQCollapse
            accordion
            ghost
            defaultActiveKey={[0]}
            expandIconPosition="end"
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            onChange={(key) => {
              posthog.capture('homepage_faq_collapse_change', {
                question: faq[parseInt(key[0])]?.question,
              });
            }}
          >
            {faq?.map((item, index) => (
              <FAQCollapsePanel
                $isMobile={isMobile}
                $isInView={isInView}
                className={`FAQCollapsePanel_${index}`}
                header={
                  <FAQCollapsePanelTitle
                    level={screens.md ? 3 : 5}
                    $isMobile={isMobile}
                  >
                    {item.question}
                  </FAQCollapsePanelTitle>
                }
                key={index}
              >
                <Col xs={24} sm={24} md={24} lg={20} xl={18} xxl={16}>
                  <FAQCollapsePanelAnswer>{item.answer}</FAQCollapsePanelAnswer>
                </Col>
              </FAQCollapsePanel>
            ))}
          </FAQCollapse>
        </Col>
      </FAQContainer>
    </FAQBackground>
  );
}
